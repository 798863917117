import type {
  AppUser,
  Fund,
  ProxyTypeEnum,
  FrequencyEnum,
  ComputedInvestmentResidual,
  DetailedProxyMetadata,
  HistoricalSubjectRangeAnalysisErrorEnum,
} from 'venn-api';
import type { FundToBulkProxy } from '../modals/pickers/types';

export interface CreateUpdateMetadata {
  owner?: AppUser;
  created?: number;
  updatedBy?: AppUser;
  updated?: number;
}

export type CommonRangeData =
  | {
      earliestStart: number;
      latestEnd: number;
      fullRange: number;
      frequency: FrequencyEnum;
      overlap: {
        percentageWidth: number;
        percentageStart: number;
      };
    }
  | undefined;

export type Timestamp = number;
export type Allocation = number;

type AgGridRowMetadata = {
  name: string;
  /** Stable and globally unique ID for ag-grid usage. */
  rowId: string;
  /**
   * A unique path identifying a row, used by ag-grid to represent the hierarchy tree data.
   * Does not need to be human-readable.
   */
  fullPath: string[];
};
export type BulkManageProFormaTotalRow = AgGridRowMetadata &
  ProFormaAllocation & {
    rowType: 'total';
    name: 'Total';
    rowId: 'total-ce15479b-b395-4066-b6cb-236b1cbbc238';
    fullPath: ['total-ce15479b-b395-4066-b6cb-236b1cbbc238'];
    secondary: true;
  };
export type BulkManageHistoricalTotalRow = AgGridRowMetadata &
  HistoricalAllocation & {
    rowType: 'total';
    name: 'Total';
    rowId: 'total-ce15479b-b395-4066-b6cb-236b1cbbc238';
    fullPath: ['total-ce15479b-b395-4066-b6cb-236b1cbbc238'];
    secondary: true;
  };
export type BulkManageAxisRow = AgGridRowMetadata & {
  rowType: 'axis';
  commonRangeData: CommonRangeData;
  name: '';
  rowId: 'axis';
  fullPath: ['axis'];
  axis: true;
};
type BulkManageDataRowCommon = AgGridRowMetadata &
  CreateUpdateMetadata & {
    startDate: Timestamp | undefined;
    endDate: Timestamp | undefined;
    frequency: FrequencyEnum | undefined;
    /**
     * Range data common to the entire range column.
     * Passing this in as data enables column defs to be static for performance.
     *
     * Undefined while loading range data.
     */
    commonRangeData: CommonRangeData;
  };
const HistoricalWarningValues = ['LATEST_ALLOCATION_DATE_EXCEEDS_SUBJECT_RETURNS_END_DATE'] as const;
export type HistoricalWarning = {
  _type: 'warning';
  value: (typeof HistoricalWarningValues)[number];
};
export const HistoricalErrorValues = [
  'EARLIEST_ALLOCATION_DATE_PREDATES_SUBJECT_START_DATE',
  'ALLOCATION_DATE_OUTSIDE_SUBJECT_RANGE',
  'INVESTMENT_RETURNS_NOT_AVAILABLE',
  'INVESTMENT_ALLOCATIONS_NOT_AVAILABLE',
] as const;
export type HistoricalError = {
  _type: 'error';
  value: (typeof HistoricalErrorValues)[number];
};
export type HistoricalWarningOrError = HistoricalWarning | HistoricalError;
// following types and variables are used by the compiler to check our defined types match that of the API's
type IsEqual<Type1, Type2> = Type1 | Type2 extends Type1 & Type2 ? true : never;
const _assertIsEqual: IsEqual<HistoricalSubjectRangeAnalysisErrorEnum, HistoricalWarningOrError['value']> = true;
export type HistoricalAllocation = {
  allocationType: 'historical';
  allocation: Record<Timestamp, Allocation>;
  historicalStartDate: Timestamp | undefined;
  historicalEndDate: Timestamp | undefined;
  historicalError: HistoricalWarningOrError | undefined;
};
export type ProFormaAllocation = {
  allocationType: 'proforma';
  allocation: Allocation | undefined;
};
type AllocationInformation = HistoricalAllocation | ProFormaAllocation;
type BulkManageInvestmentRowCommon = AllocationInformation & {
  rowType: 'investment';
};
export type BulkManagePortfolioRow = BulkManageDataRowCommon &
  BulkManageInvestmentRowCommon & {
    investmentType: 'portfolio';
    rangeLoading: false;
    // we only show portfolios on the bulk manage page if they are benchmarks
    secondary: true;
    isBenchmark: true;
  };
export type BulkManageStrategyRow = BulkManageDataRowCommon &
  BulkManageInvestmentRowCommon & {
    investmentType: 'strategy';
  };
export type BulkManageFundRow = BulkManageDataRowCommon &
  BulkManageInvestmentRowCommon & {
    investmentType: 'fund';
    dataSource: string;
    investment: FundToBulkProxy;
    fundProxyInfo: DetailedProxyMetadata | undefined;
    rangeLoading: boolean;

    /** If true, the row is not part of the main data set, such as factor range or benchmark. */
    secondary: boolean;
    isBenchmark: boolean;
    proxyStartDate: Timestamp | undefined;
    proxyEndDate: Timestamp | undefined;
    extrapolateStartDate: Timestamp | undefined;
    extrapolateEndDate: Timestamp | undefined;
  };
export type BulkManageFactorRow = BulkManageDataRowCommon & {
  rowType: 'factor';
  name: 'Factors';
  secondary: true;
  dataSource: string;
};
export type BulkManageInvestmentRow = BulkManagePortfolioRow | BulkManageStrategyRow | BulkManageFundRow;
export type BulkManageRow =
  | BulkManageAxisRow
  | BulkManageFactorRow
  | BulkManageInvestmentRow
  | BulkManageHistoricalTotalRow
  | BulkManageProFormaTotalRow;

export interface OldBulkManageRow extends CreateUpdateMetadata {
  selected?: boolean;
  name: string;
  investment?: Fund;
  investmentId?: string;
  investmentLoading?: boolean;
  allocation?: number;
  dataSource?: string;
  rangeLoading?: boolean;
  startDate?: number;
  endDate?: number;
  historicalStartDate?: number;
  historicalEndDate?: number;
  frequency?: FrequencyEnum;
  proxyStartDate?: number;
  proxyEndDate?: number;
  proxyType?: ProxyTypeEnum;
  secondary?: boolean; // Is this row not part of the main data set? i.e. factor range
  isIntegration?: boolean;
  isBenchmark?: boolean;
  isStrategy?: boolean;
  isLive?: boolean;
  investmentForecast?: ComputedInvestmentResidual;
  investmentForecastLoading?: boolean;
  extrapolateStartDate?: number;
  extrapolateEndDate?: number;
}

export enum BulkManageAction {
  FUND_MODIFIED,
  INVESTMENT_FORECAST_MODIFIED,
}

export type BulkManageUpdateFn = (action: BulkManageAction, row: BulkManageRow, investmentId?: string) => Promise<void>;
export type OldBulkManageUpdateFn = (
  action: BulkManageAction,
  row: OldBulkManageRow,
  investmentId?: string,
) => Promise<void>;
