import React, { useState } from 'react';
import type { SearchMenuItem } from 'venn-components';
import { useModal } from 'venn-utils';
import SearchMenuBar from '../../../../../search-menu/SearchMenuBar';
import { MappedPortfolioNodeCellRendererRoot } from './MappedPortfolioNodeCellRendererRoot';
import type { MappedPortfolioNodeCellRendererProps } from './types';
import { useMappedPortfolioSearchMenuBar } from './useMappedPortfolioSearchMenuBar';

export const MappedProFormaPortfolioNodeCellRenderer = ({
  isFundIdDisabledForMapping,
  onApplyMapping,
  node,
  needsMapping,
  isFund,
  isExcluded,
}: MappedPortfolioNodeCellRendererProps) => {
  const fundName = node?.data?.node?.name;
  const [isSearchOpen, openSearch, closeSearch] = useModal();
  const {
    refreshedStyling,
    autofocus,
    defaultMenuIsOpen,
    privateAssetSearchMode,
    investmentsOnly,
    isOptionDisabled,
    customPlaceholder,
    optionDisabledTooltipContent,
    getOptionValue,
    onBlur,
    onSelected,
    location,
  } = useMappedPortfolioSearchMenuBar({
    closeSearch,
    node,
    onApplyMapping,
    isFundIdDisabledForMapping,
    fundName,
  });
  const [isNodeRemapped, setIsNodeRemapped] = useState<boolean>(false);
  const onSelectedWrapper = (selected: SearchMenuItem) => {
    onSelected(selected);
    setIsNodeRemapped(true);
  };

  return (
    <MappedPortfolioNodeCellRendererRoot
      openSearch={openSearch}
      isSearchOpen={isSearchOpen}
      closeSearch={closeSearch}
      isFund={isFund}
      isExcluded={isExcluded}
      isRemapped={isNodeRemapped}
      needsMapping={needsMapping}
      shouldCloseSearchOnScroll
    >
      <SearchMenuBar
        refreshedStyling={refreshedStyling}
        autofocus={autofocus}
        defaultMenuIsOpen={defaultMenuIsOpen}
        privateAssetSearchMode={privateAssetSearchMode}
        investmentsOnly={investmentsOnly}
        isOptionDisabled={isOptionDisabled}
        customPlaceholder={customPlaceholder}
        optionDisabledTooltipContent={optionDisabledTooltipContent}
        getOptionValue={getOptionValue}
        onBlur={onBlur}
        onSelected={onSelectedWrapper}
        location={location}
      />
    </MappedPortfolioNodeCellRendererRoot>
  );
};
