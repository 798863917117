import { invalidateForecast, invalidateInvestment, invalidateInvestments } from './invalidation-internal';
import type { Satisfies, Serializable } from '../type';
import { createBroadcastChannel } from './safe-broadcast-channel';

/**
 * Functions that can be called to invalidate data in the Venn web application.
 * Add a function here to expose it to the invalidation system and make it usable with {@link invalidate}.
 *
 * Functions may only take arguments that are serializable by the structured clone algorithm.
 */
const invalidationFunctions = {
  forecast: invalidateForecast,
  investment: invalidateInvestment,
  investments: invalidateInvestments,
};
type InvalidationFunctions = typeof invalidationFunctions;

/**
 * Fires an invalidation function across all tabs and windows of the Venn web application in this browser,
 * and returns the result of running the function on this tab.
 */
export function invalidate<T extends keyof InvalidationFunctions>(
  functionName: T,
  ...payload: Parameters<InvalidationFunctions[T]>
): ReturnType<InvalidationFunctions[T]> {
  invalidationBroadcastChannel?.postMessage({ functionName, payload });
  return invalidationFunctions[functionName].apply(null, payload);
}

type InvalidationMessage = {
  functionName: keyof InvalidationFunctions;
  payload: Parameters<InvalidationFunctions[keyof InvalidationFunctions]>;
};

// this type is unused because it is just a type assertion
type _AssertSerializableFunctionArgs = Satisfies<
  InvalidationFunctions,
  Record<keyof InvalidationFunctions, (...args: Serializable[]) => never>
>;

const invalidationBroadcastChannel = createBroadcastChannel<InvalidationMessage>('venn-invalidation', {
  ignoreSelf: true,
});
invalidationBroadcastChannel?.addEventListener('message', ({ data }) => {
  invalidationFunctions[data.functionName].apply(null, data.payload);
});
