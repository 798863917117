import type { ComponentProps } from 'react';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { TooltipBodyDirection, TooltipPosition } from '../enums';
import { ZIndex } from '../../zIndexValues';
import { LegacyRelativePortal } from '../relative-portal';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { IS_JEST_TEST, useDebugValue, useWindowSize } from 'venn-utils';
import CSSTransition from 'react-transition-group/CSSTransition';

export const POINTER_SIZE = 3;
export const LARGER_POINTER_SIZE = 9;

const getSize = (props: PosesProtoProps) => (props.largerPointer ? LARGER_POINTER_SIZE : POINTER_SIZE);

export const getNewPosition = (center: number, maxTooltipWidth: number, windowWidth: number) => {
  const tooltipLeft = center - maxTooltipWidth / 2; // left boundary edge is ~ root center - max width of tooltip / 2
  const tooltipRight = center + maxTooltipWidth / 2; // right boundary edge is ~ root center + max width of tooltip / 2

  if (tooltipRight > windowWidth) {
    return TooltipPosition.Left;
  }

  if (tooltipLeft < 0) {
    return TooltipPosition.Right;
  }

  return null;
};

interface ContentProps {
  background: string;
  showShadow: boolean;
}
export const TooltipContentStyled = styled.div<ContentProps>`
  padding: 8px;
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  color: white;

  background-color: ${(props) => props.background};
  line-height: normal;
  word-break: break-word;
  white-space: normal;

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${(props) =>
    props.showShadow
      ? css`
          box-shadow: 0 1px 10px 0 rgba(102, 102, 102, 0.6);
        `
      : ''};
`;

interface ContentContainerProps {
  width: number;
  direction?: TooltipBodyDirection;
  position: TooltipPosition;
}
export const TooltipContentContainerStyled = styled.div<ContentContainerProps>`
  position: absolute;

  display: flex;
  width: ${(props: { width: number }) => `${props.width}px`};

  ${(props) => {
    switch (props.direction) {
      case TooltipBodyDirection.Left:
        return css`
          right: 0;
          justify-content: flex-end;
        `;
      case TooltipBodyDirection.Right:
        return css`
          left: 0;
        `;
      case TooltipBodyDirection.Center:
        return css`
          justify-content: center;
        `;
      default:
        return '';
    }
  }};
`;

export interface TooltipProps extends Omit<React.BaseHTMLAttributes<HTMLDivElement>, 'content'> {
  position?: TooltipPosition;
  bodyDirection?: TooltipBodyDirection;
  maxWidth?: number;
  className?: string;
  hideDelay?: number;
  isHidden?: boolean;
  content?: React.ReactNode;
  background?: string;
  plain?: boolean;
  block?: boolean;
  /**
   * Keeps the tooltip open when hovering over the tooltip itself
   * Used for cases where content in the tooltip is interactive
   */
  interactive?: boolean;
  hideArrow?: boolean;
  showShadow?: boolean;
  largerPointer?: boolean;
  usePortal?: boolean;
  unmountOnExit?: boolean;
  /**
   * Only valid when usePortal is true. The position props to pass through to the RelativePortal
   */
  portalPosition?: { left?: number; right?: number; top?: number };
  /**
   * Only valid when usePortal is true. The element to which the portal will be relative
   */
  portalRelativeElement?: Element;
  /**
   * Flag for making the root element flex
   */
  flex?: boolean;
  zIndex?: number;
  debugForceVisible?: boolean;
  /** Whether to force the tooltip to always be in the DOM, even when it isn't hovered. This should never be used, but exists for legacy usage in tests. */
  legacyForceAlwaysInDom?: boolean;
  /** Report zoom (if applicable) in order to change position of tooltip on zoom if needed */
  zoom?: number;
}

interface BaseTipProps extends TooltipProps {
  direction?: TooltipBodyDirection;
  rootHovered: boolean;
  tooltipHovered: boolean;
  rootWidth: number;
  rootHeight: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const DEFAULT_TOOLTIP_BACKGROUND = 'rgba(16, 22, 27, 0.9)';

export const TOOLTIP_ANIMATION_CLASS_NAME = 'venn-tooltip';
const TOOLTIP_ANIMATION_DURATION_MS = 200;

const createTransitionStyle = (
  startingStyle: FlattenSimpleInterpolation,
  endingStyle: FlattenSimpleInterpolation,
) => css`
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-enter {
    ${startingStyle}
  }
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-enter-active {
    ${endingStyle}
  }
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-exit {
    ${endingStyle}
  }
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-exit-active {
    ${startingStyle}
  }
`;

const BaseTip = ({
  position = TooltipPosition.Top,
  direction,
  background = DEFAULT_TOOLTIP_BACKGROUND,
  maxWidth = 220,
  hideDelay = 0,
  isHidden = false,
  content,
  interactive = false,
  hideArrow = false,
  showShadow = false,
  largerPointer = false,
  usePortal = false,
  zIndex = ZIndex.ModalCover,
  debugForceVisible = false,
  legacyForceAlwaysInDom = false,
  rootHovered,
  tooltipHovered,
  rootWidth,
  rootHeight,
  onMouseEnter,
  onMouseLeave,
}: BaseTipProps) => {
  const Pose = usePortal ? PortalPoses[position] : Poses[position];

  if (!content || isHidden) {
    return null;
  }

  return (
    <CSSTransition
      classNames={TOOLTIP_ANIMATION_CLASS_NAME}
      timeout={{ enter: TOOLTIP_ANIMATION_DURATION_MS, exit: TOOLTIP_ANIMATION_DURATION_MS + hideDelay }}
      in={rootHovered || (interactive && tooltipHovered) || debugForceVisible}
      mountOnEnter={!legacyForceAlwaysInDom}
      unmountOnExit={!legacyForceAlwaysInDom}
    >
      <Pose
        data-testid="tooltip"
        zIndex={zIndex}
        hideDelay={hideDelay}
        background={background}
        interactive={interactive}
        showShadow={showShadow}
        largerPointer={largerPointer}
        hideArrow={hideArrow}
        rootWidth={rootWidth}
        rootHeight={rootHeight}
        usePortal={usePortal}
      >
        <TooltipContentContainerStyled width={maxWidth} direction={direction} position={position}>
          <TooltipContentStyled
            background={background}
            showShadow={showShadow}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {content}
          </TooltipContentStyled>
        </TooltipContentContainerStyled>
      </Pose>
    </CSSTransition>
  );
};

const TooltipInternal = ({
  position = TooltipPosition.Top,
  background = DEFAULT_TOOLTIP_BACKGROUND,
  maxWidth = 220,
  className,
  hideDelay = 0,
  isHidden = false,
  content,
  children,
  plain = false,
  block = false,
  interactive = false,
  hideArrow = false,
  showShadow = false,
  largerPointer = false,
  usePortal = false,
  portalPosition = {},
  portalRelativeElement,
  flex = false,
  zIndex = ZIndex.ModalCover,
  debugForceVisible = false,
  legacyForceAlwaysInDom = false,
  zoom,
  ...htmlProps
}: TooltipProps) => {
  const [rootHovered, setRootHovered] = useState(false);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(position);

  const { width: windowWidth } = useWindowSize();

  const rootRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (usePortal) {
      // update position of RelativePortal by dispatching resize event
      window.dispatchEvent(new Event('resize'));
    }
  }, [usePortal]);

  useLayoutEffect(() => {
    // switch tooltip position to left or right if boundary crosses window
    if (usePortal) {
      const resizeObserver = new ResizeObserver((entries) => {
        // if tooltip is already specified to orient to the left or right, don't re-position
        if (htmlProps.bodyDirection != null && htmlProps.bodyDirection !== TooltipBodyDirection.Center) {
          return;
        }

        const element = entries[0]!.target;
        const { left, width } = element.getBoundingClientRect();

        const newPosition = getNewPosition(left + width / 2, maxWidth, windowWidth);
        setTooltipPosition(newPosition ?? position);
      });

      if (rootRef.current) {
        resizeObserver.observe(rootRef.current);
      }

      return () => resizeObserver.disconnect();
    }

    return undefined;
  }, [usePortal, position, windowWidth, maxWidth, htmlProps.bodyDirection, zoom]);

  const tooltipPortalStyle = { zIndex };
  let bodyDirection: TooltipBodyDirection | undefined;

  switch (tooltipPosition) {
    case TooltipPosition.Top:
    case TooltipPosition.Bottom:
      bodyDirection = htmlProps.bodyDirection || TooltipBodyDirection.Center;
      break;
    case TooltipPosition.Left:
      bodyDirection = TooltipBodyDirection.Left;
  }

  const Root = plain ? RootPlain : flex ? RootFlex : block ? RootBlock : RootDefault;
  const tip = (
    <BaseTip
      position={tooltipPosition}
      direction={bodyDirection}
      background={background}
      maxWidth={maxWidth}
      hideDelay={hideDelay}
      isHidden={isHidden}
      content={content}
      interactive={interactive}
      hideArrow={hideArrow}
      showShadow={showShadow}
      largerPointer={largerPointer}
      usePortal={usePortal}
      zIndex={zIndex}
      debugForceVisible={debugForceVisible}
      legacyForceAlwaysInDom={legacyForceAlwaysInDom}
      rootHovered={rootHovered}
      tooltipHovered={tooltipHovered}
      // TODO: pose takes only defined numbers and yet we're giving it |undefined here...?
      rootWidth={rootRef.current?.getBoundingClientRect().width as AnyDuringEslintMigration}
      rootHeight={rootRef.current?.getBoundingClientRect().height as AnyDuringEslintMigration}
      onMouseEnter={() => setTooltipHovered(true)}
      onMouseLeave={() => setTooltipHovered(false)}
    />
  );

  return (
    <Root
      className={className}
      ref={rootRef}
      {...htmlProps}
      onMouseEnter={() => setRootHovered(true)}
      onMouseLeave={() => setRootHovered(false)}
    >
      {children}
      {usePortal ? (
        <LegacyRelativePortal
          component="div"
          fullWidth
          {...portalPosition}
          relativeElement={portalRelativeElement}
          style={tooltipPortalStyle}
        >
          {tip}
        </LegacyRelativePortal>
      ) : (
        tip
      )}
    </Root>
  );
};

export const Tooltip = (props: ComponentProps<typeof TooltipInternal>) => {
  // HACK: Many tests were written with the assumption that tooltips are always in the DOM,
  // so in testing we have to force this to true even though it is less performant and we don't
  // do it anymore outside of tests.
  const forceAlwaysInDom = IS_JEST_TEST || !!window.Cypress;

  const forceShowTooltips = useDebugValue('debugShowAllTooltips');
  const forceUsePortal = useDebugValue('debugForceTooltipPortal');
  const forceInteractive = useDebugValue('debugForceTooltipInteractive');

  return (
    <TooltipInternal
      {...props}
      interactive={props.interactive || forceInteractive}
      usePortal={props.usePortal || forceUsePortal}
      legacyForceAlwaysInDom={forceAlwaysInDom}
      debugForceVisible={props.debugForceVisible || forceShowTooltips}
    />
  );
};

export default Tooltip;

const RootPlain = styled.span`
  width: 100%;
`;

const RootDefault = styled.span`
  position: relative;
  display: inline-block;
`;

const RootFlex = styled(RootDefault)`
  display: flex;
`;

const RootBlock = styled(RootDefault)`
  display: block;
  width: 100%;

  flex: 1;
`;

interface PosesProtoProps {
  showShadow: boolean;
  hideDelay: number;
  background: string;
  interactive: boolean;
  hideArrow: boolean;
  largerPointer: boolean;
  rootWidth: number;
  rootHeight: number;
  usePortal: boolean;
  zIndex: number;
}

const PosesProto = styled.div<PosesProtoProps>`
  position: absolute;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  z-index: ${({ zIndex }) => zIndex ?? ZIndex.ModalCover};
  width: 100%;
  opacity: 0;

  ${(props) =>
    props.interactive &&
    css`
      pointer-events: auto;
    `}

  &:after {
    position: absolute;
    width: 0px;
    height: 0px;
    border: ${(props) =>
      props.largerPointer ? `${LARGER_POINTER_SIZE}px solid transparent` : `${POINTER_SIZE}px solid transparent`};
    content: '';
    border-color: ${(props) => `transparent transparent ${props.background} ${props.background}`};
    ${(props) =>
      props.showShadow
        ? css`
            box-shadow: -2px 2px 2px 0 rgba(102, 102, 102, 0.2);
          `
        : ''};
  }

  transition:
    opacity ${TOOLTIP_ANIMATION_DURATION_MS}ms ease-in-out,
    transform ${TOOLTIP_ANIMATION_DURATION_MS}ms cubic-bezier(0.71, 1.7, 0.77, 1.24);

  &.${TOOLTIP_ANIMATION_CLASS_NAME}-enter {
    opacity: 0;
  }
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-enter-active, &.${TOOLTIP_ANIMATION_CLASS_NAME}-enter-done {
    opacity: 1;
  }
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-exit {
    opacity: 1;
    transition-delay: ${(props: { hideDelay: number }) => (props.hideDelay > 0 ? `${props.hideDelay}ms` : '0ms')};
  }
  &.${TOOLTIP_ANIMATION_CLASS_NAME}-exit-active {
    opacity: 0;
  }
`;

const Poses: { [_id in TooltipPosition]: React.FC<React.PropsWithChildren<PosesProtoProps>> } = {
  [TooltipPosition.Top]: styled(PosesProto)`
    bottom: ${(props) => `calc(${getSize(props) * 2}px + 100%)`};
    align-items: center;
    justify-content: flex-end;
    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      bottom: ${(props) => `${-getSize(props)}px`};
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
    }

    ${createTransitionStyle(
      css`
        transform: translateY(10px);
      `,
      css`
        transform: translateY(0);
      `,
    )}
  `,
  [TooltipPosition.Bottom]: styled(PosesProto)`
    top: ${(props) => `calc(${getSize(props) * 2}px + 100%)`};
    align-items: center;
    justify-content: flex-start;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      top: ${(props) => `${-getSize(props)}px`};
      left: 50%;
      transform: translateX(-50%) rotate(135deg);
    }

    ${createTransitionStyle(
      css`
        transform: translateY(-10px);
      `,
      css`
        transform: translateY(0);
      `,
    )}
  `,
  [TooltipPosition.Left]: styled(PosesProto)`
    top: 50%;
    right: ${(props) => `calc(${getSize(props) * 2}px + 100%)`};
    align-items: flex-end;
    justify-content: center;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      right: ${(props) => `${-getSize(props)}px`};
      top: 50%;
      transform: translateY(-50%) rotate(225deg);
    }

    ${createTransitionStyle(
      css`
        transform: translate(10px, -50%);
      `,
      css`
        transform: translate(0, -50%);
      `,
    )}
  `,
  [TooltipPosition.Right]: styled(PosesProto)`
    top: 50%;
    left: ${(props) => `calc(${getSize(props) * 2}px + 100%)`};
    align-items: flex-start;
    justify-content: center;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      left: ${(props) => `${-getSize(props)}px`};
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    ${createTransitionStyle(
      css`
        transform: translate(-10px, -50%);
      `,
      css`
        transform: translate(0, -50%);
      `,
    )}
  `,
};

const PortalPoses: { [_id in TooltipPosition]: React.FC<React.PropsWithChildren<PosesProtoProps>> } = {
  [TooltipPosition.Top]: styled(PosesProto)`
    bottom: ${(props) => getSize(props) * 2 + props.rootHeight}px;
    align-items: center;
    justify-content: flex-end;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      bottom: ${(props) => `${-getSize(props)}px`};
      transform: rotate(-45deg);
    }

    ${createTransitionStyle(
      css`
        transform: translateY(10px);
      `,
      css`
        transform: translateY(0);
      `,
    )}
  `,
  [TooltipPosition.Bottom]: styled(PosesProto)`
    top: ${(props) => getSize(props) * 2}px;
    align-items: center;
    justify-content: flex-start;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      top: ${(props) => `${-getSize(props)}px`};
      transform: rotate(135deg);
    }

    ${createTransitionStyle(
      css`
        transform: translateY(-10px);
      `,
      css`
        transform: translateY(0);
      `,
    )}
  `,
  [TooltipPosition.Left]: styled(PosesProto)`
    top: ${(props) => -props.rootHeight / 2}px;
    right: ${(props) => `calc(${getSize(props) * 2}px + 100%)`};
    align-items: flex-end;
    justify-content: center;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      right: ${(props) => `${-getSize(props)}px`};
      transform: rotate(225deg);
    }

    ${createTransitionStyle(
      css`
        transform: translate(10px, -50%);
      `,
      css`
        transform: translate(0, -50%);
      `,
    )}
  `,
  [TooltipPosition.Right]: styled(PosesProto)`
    top: ${(props) => -props.rootHeight / 2}px;
    left: ${(props) => `${props.rootWidth + getSize(props) * 2}px`};
    align-items: flex-start;
    justify-content: center;

    &:after {
      opacity: ${(props) => (props.hideArrow ? '0' : 'initial')};
      left: ${(props) => `${-getSize(props)}px`};
      transform: rotate(45deg);
    }

    ${createTransitionStyle(
      css`
        transform: translate(-10px, -50%);
      `,
      css`
        transform: translate(0, -50%);
      `,
    )}
  `,
};
