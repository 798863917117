import React from 'react';
import { useModal } from 'venn-utils';
import { serializePath } from '../../../../../modals/data-uploader/views/review/helpers';
import { MappedPortfolioNodeCellRendererRoot } from './MappedPortfolioNodeCellRendererRoot';
import type { MappedPortfolioNodeCellRendererProps } from './types';
import { useMappedPortfolioSearchMenuBar } from './useMappedPortfolioSearchMenuBar';
import SearchMenuModal from '../../../../../search-menu/SearchMenuModal';
import styled from 'styled-components';
import { Button, GetColor, Icon, ZIndex } from 'venn-ui-kit';
import { isNil, noop } from 'lodash';
import type { Fund } from 'venn-api';

type PathFund = {
  path: number[];
  fund: Fund;
};
export const MappedHistoricalPortfolioNodeCellRenderer = ({
  isFundIdDisabledForMapping,
  onApplyMapping,
  node,
  needsMapping,
  isFund,
  isPathRemapped,
  isExcluded,
}: MappedPortfolioNodeCellRendererProps) => {
  const fundName = node?.data?.node?.name;
  const [isSearchOpen, openSearch, closeSearch] = useModal();
  const [pathFund, setPathFund] = React.useState<PathFund | undefined>(undefined);
  const {
    autofocus,
    defaultMenuIsOpen,
    privateAssetSearchMode,
    investmentsOnly,
    isOptionDisabled,
    customPlaceholder,
    optionDisabledTooltipContent,
    getOptionValue,
    onSelected,
    location,
  } = useMappedPortfolioSearchMenuBar({
    closeSearch: noop,
    node,
    onApplyMapping: (path, fund) => {
      setPathFund({ path, fund });
    },
    isFundIdDisabledForMapping,
    fundName,
  });
  const isNodeRemapped = node.data?.path ? isPathRemapped[serializePath(node.data?.path.slice(1)).value]! : false;
  const onConfirmRemap = () => {
    onApplyMapping(pathFund!.path, pathFund!.fund);
    closeSearch();
  };

  return (
    <MappedPortfolioNodeCellRendererRoot
      openSearch={openSearch}
      isSearchOpen={isSearchOpen}
      closeSearch={closeSearch}
      isFund={isFund}
      isExcluded={isExcluded}
      isRemapped={isNodeRemapped}
      needsMapping={needsMapping}
      shouldCloseSearchOnScroll={false}
    >
      <Container needsRemapping={needsMapping}>
        <SpaceBetween>
          <Title>
            {'Map '}
            <FundName>{fundName}</FundName>
            {' to'}
          </Title>
          <button type="button" aria-label="Close dialog" onClick={closeSearch}>
            <CloseButton type="times" />
          </button>
        </SpaceBetween>
        <SearchMenuModal
          displayResultsAsBlock
          refreshedStyling={false}
          autofocus={autofocus}
          defaultMenuIsOpen={defaultMenuIsOpen}
          privateAssetSearchMode={privateAssetSearchMode}
          investmentsOnly={investmentsOnly}
          isOptionDisabled={isOptionDisabled}
          customPlaceholder={customPlaceholder}
          optionDisabledTooltipContent={optionDisabledTooltipContent}
          getOptionValue={getOptionValue}
          onSelected={onSelected}
          location={location}
        />
        <Spacer />
        <Footer>
          Mapping applies across all dates in portfolio
          <Button
            aria-label="apply mapping"
            disabled={isNil(pathFund)}
            dominant
            dense
            noMargin
            onClick={onConfirmRemap}
          >
            MAP
          </Button>
        </Footer>
      </Container>
    </MappedPortfolioNodeCellRendererRoot>
  );
};

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`;
const Spacer = styled.div`
  margin: 0 20px;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 20px;
`;
const Title = styled.div`
  display: inline;
  align-items: center;
  font-size: 14px;
`;
const FundName = styled.span`
  font-weight: bold;
`;
const CloseButton = styled(Icon)`
  color: ${GetColor.Primary.Dark};
`;
const Container = styled.div<{ needsRemapping: boolean }>`
  z-index: ${ZIndex.Front};
  border: 2px solid ${({ needsRemapping }) => (needsRemapping ? GetColor.Error : GetColor.DarkBlue)};
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
  background-color: ${GetColor.White};
  display: flex;
  flex-direction: column;
`;
