import type { PrintOrientationTypeEnum } from 'venn-api';
import {
  DEFAULT_LANDSCAPE_HEIGHT,
  DEFAULT_LANDSCAPE_WIDTH,
  DEFAULT_PORTRAIT_HEIGHT,
  DEFAULT_PORTRAIT_WIDTH,
} from 'venn-state';

export interface PageDimensions {
  totalWidth: number;
  totalHeight: number;
  gridSettings: GridSettings;
}

/** A derived subset of the grid settings available to react-grid-layout and ReactGridLayoutProps. */
export interface GridSettings {
  width: number;
  height: number;
  noColumns: number;
  noRows: number;
  /** Margin between items `[x, y]` in px. */
  margin: [number, number];
  /** Padding of edges of the container `[x, y]` in px. */
  containerPadding: [number, number];
}

export const RL_GRID_ROW_HEIGHT = 35;

/**
 * While [10, 10] is the default margin for react-grid-layout, we set it explicitly to increase visibility
 * and ensure we are resilient to library changes.
 */
const DEFAULT_BLOCK_MARGIN: [number, number] = [7, 7];

// TODO: make the magic numbers in gridSettings less magic: where do they come from? can they be computed instead of hardcoded?
export const dimensions: { [_orientation in PrintOrientationTypeEnum]: PageDimensions } = {
  LANDSCAPE: {
    totalWidth: DEFAULT_LANDSCAPE_WIDTH,
    totalHeight: DEFAULT_LANDSCAPE_HEIGHT,
    gridSettings: {
      width: 1072,
      height: 640,
      noColumns: 10,
      noRows: 15,
      margin: DEFAULT_BLOCK_MARGIN,
      containerPadding: DEFAULT_BLOCK_MARGIN,
    },
  },
  PORTRAIT: {
    totalWidth: DEFAULT_PORTRAIT_WIDTH,
    totalHeight: DEFAULT_PORTRAIT_HEIGHT,
    gridSettings: {
      width: 752,
      height: 950,
      noColumns: 4,
      noRows: 23,
      margin: DEFAULT_BLOCK_MARGIN,
      containerPadding: DEFAULT_BLOCK_MARGIN,
    },
  },
};

export function calcGridColWidth(gridSettings: GridSettings): number {
  const { margin, containerPadding, width, noColumns } = gridSettings;
  return (width - margin[0] * (noColumns - 1) - containerPadding[0] * 2) / noColumns;
}

export function calcGridItemWidth(gridSettings: GridSettings, widthInColumns: number): number {
  if (!Number.isFinite(widthInColumns)) return widthInColumns;
  return Math.round(
    widthInColumns * calcGridColWidth(gridSettings) + Math.max(0, widthInColumns - 1) * gridSettings.margin[0],
  );
}
